export const validationsFormCreate = {
  name: {
    required: true,
    maxLength: 100,
  },
  companyName: {
    required: true,
    maxLength: 100,
  },
  email: {
    required: true,
    validateEmail: true,
    maxLength: 64,
  },
  password: {
    required: true,
    minLength: 6,
    maxLength: 10,
  },
  passwordConfirm: {
    required: true,
    minLength: 6,
    maxLength: 10,
    compare: 'password',
  },
  phone: {
    validateNumber: true,
    maxLength: 50,
  },
  address: {
    maxLength: 100,
  },
};
