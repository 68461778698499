import React, { useCallback, useState } from 'react';
import { FiLogIn } from 'react-icons/fi';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  NavLink,
  Alert,
} from 'reactstrap';
import { login } from '../../flux/actions/authActions';
import { clearErrors } from '../../flux/actions/errorActions';
import { validateForm } from '../../helper/validateForm';
import { IAuthReduxProps, ILoginModal } from '../../types/interfaces';
import { validations } from './validations';

const LoginModal = ({
  isAuthenticated,
  error,
  login,
  clearErrors,
}: ILoginModal) => {
  const [modal, setModal] = useState(!isAuthenticated);

  const [user, setUser] = useState({
    username: '',
    password: '',
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const [errors, setErrors] = useState({
    username: null,
    password: null,
  });

  const handleToggle = useCallback(() => {
    setErrors({
      username: null,
      password: null,
    });
    user.username = '';
    user.password = '';
    setModal(!modal);
    clearErrors();
  }, [clearErrors, modal]);

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const { errors, isFormValidate } = validateForm(validations, user);
    setErrors(errors);
    if (isFormValidate) {
      login(user);
    }
  };

  return (
    <div>
      <NavLink onClick={handleToggle} href="#">
        <FiLogIn className="mr-2" />
        ログイン
      </NavLink>

      <Modal isOpen={modal} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>ログイン</ModalHeader>
        <ModalBody>
          {error.message ? <Alert color="danger">{error.message}</Alert> : null}
          <Form onSubmit={handleOnSubmit}>
            <FormGroup>
              <Label for="email">ユーザー名</Label>
              <Input
                type="text"
                name="username"
                value={user.username}
                id="username"
                placeholder="ユーザー名"
                className={`${errors.username ? 'border-field-error' : ''}`}
                onChange={handleChange}
              />
              {errors.username ? (
                <div className="message-field-error mx-auto">
                  {`${errors.username}`}
                </div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label for="password" className="mt-3">
                パスワード
              </Label>
              <Input
                type="password"
                name="password"
                id="password"
                value={user.password}
                placeholder="パスワード"
                className={`${errors.password ? 'border-field-error' : ''}`}
                onChange={handleChange}
              />
              {errors.password ? (
                <div className="message-field-error mx-auto">
                  {`${errors.password}`}
                </div>
              ) : null}
            </FormGroup>
            <Button
              color="dark"
              style={{ marginTop: '2rem' }}
              block
              onClick={handleOnSubmit}
            >
              ログイン
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state: IAuthReduxProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { login, clearErrors })(LoginModal);
