export const initialValues = {
  name: '',
  companyName: '',
  email: '',
  password: '',
  passwordConfirm: '',
  phone: '',
  address: '',
  language: 'japanese',
};
