import { messages } from '../constants/messages';

const validatedRequired = (value: string) => {
  let error = null;
  if (!value || !value.trim().length) {
    error = messages.FIELD_IS_REQUIRED;
  }
  return error;
};

const validateEmail = (value: string) => {
  let error = null;
  const regexMail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (!regexMail.test(value)) {
    error = messages.INVALID_EMAIL;
  }
  return error;
};

const validatePassword = (value: string) => {
  let error = null;
  if (false) {
    error = `is not valid !`;
  }
  return error;
};

const validateNumber = (value: string) => {
  let error = null;
  const regexNumber = /^(\s*|\d+)$/;
  if (!regexNumber.test(value)) {
    error = messages.INVALID_PHONE;
  }
  return error;
};

const validateMinLength = (name: string, value: string, minLength: number) => {
  let error = null;
  if (value.length < minLength) {
    switch (name) {
      case 'password':
        error = messages.INVALID_PASSWORD;
        break;
      case 'passwordConfirm':
        error = messages.INVALID_PASSWORD_CONFIRM;
        break;

      default:
        break;
    }
  }
  return error;
};

const validateMaxLength = (name: string, value: string, maxLength: number) => {
  let error = null;
  if (value.length > maxLength) {
    switch (name) {
      case 'name':
        error = messages.INVALID_NAME;
        break;
      case 'companyName':
        error = messages.INVALID_COMPANY_NAME;
        break;
      case 'email':
        error = messages.INVALID_EMAIL_LENGTH;
        break;
      case 'password':
        error = messages.INVALID_PASSWORD;
        break;
      case 'passwordConfirm':
        error = messages.INVALID_PASSWORD_CONFIRM;
        break;
      case 'phone':
        error = messages.INVALID_PHONE_LENGTH;
        break;
      case 'address':
        error = messages.INVALID_ADDRESS;
        break;
      default:
        break;
    }
  }
  return error;
};

const validateCompare = (value: string, valueCompare: string) => {
  let error = null;
  if (value !== valueCompare) {
    error = messages.PASSWORD_CONFIRM_NOT_MATCHED;
  }
  return error;
};

export const validateForm = (validations: any, data: any) => {
  let errors: any = {};
  for (const fieldName in validations) {
    const validates = validations[fieldName];
    const value = data[fieldName];

    for (const validateType in validates) {
      let error: any;
      switch (validateType) {
        case 'required':
          error = validatedRequired(value);
          break;
        case 'validateEmail':
          error = validateEmail(value);
          break;
        case 'validatePassword':
          error = validatePassword(value);
          break;
        case 'validateNumber':
          error = validateNumber(value);
          break;
        case 'minLength':
          error = validateMinLength(fieldName, value, validates[validateType]);
          break;
        case 'maxLength':
          error = validateMaxLength(fieldName, value, validates[validateType]);
          break;
        case 'compare':
          const fieldCompare = validates[validateType];
          const valueCompare = data[fieldCompare];
          error = validateCompare(value, valueCompare);
          break;
        default:
          break;
      }
      if (error) {
        errors[fieldName] = error;
        break;
      }
    }
  }
  const isFormValidate = Object.values(errors).every((error) => !error);
  return { errors, isFormValidate };
};
