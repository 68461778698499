import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Fragment, useEffect } from 'react';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { connect } from 'react-redux';
import { Container } from 'reactstrap';
import './App.css';
import AppNavbar from './components/AppNavbar';
import CompanyForm from './components/company/CompanyForm';
import CompanyList from './components/company/CompanyList';
import { loadUser } from './flux/actions/authActions';
import store from './flux/store';
import { IItemReduxProps } from './types/interfaces';

const App = (props: any) => {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);

  return (
    <div className="App">
      <ReactNotification />
      <AppNavbar />
      <Container>
        {props.isAuthenticated ? (
          <Fragment>
            <CompanyForm />
            <CompanyList />
          </Fragment>
        ) : (
          <div className="text-center">
            <img
              className="mr-2"
              src="./video-call.png"
              style={{ width: 50 }}
              alt="call"
            />
            <h5 className="mb-3 ml-4 mt-3">管理画面にログインしてください。</h5>
          </div>
        )}
      </Container>
    </div>
  );
};

const mapStateToProps = (state: IItemReduxProps) => ({
  isAuthenticated: state.auth.isAuthenticated,
});
export default connect(mapStateToProps)(App);
