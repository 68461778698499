import axios from 'axios';
import { messages } from '../../constants/messages';
import {
  errorNotifications,
  successNotifications,
} from '../../helper/notifications';
import { IItem } from '../../types/interfaces';
import { tokenConfig } from './authActions';
import { returnErrors } from './errorActions';
import {
  ADDING_ITEM,
  ADD_ITEM_FAILURE,
  ADD_ITEM_SUCCESS,
  AUTH_ERROR,
  DELETE_ITEM,
  GET_ITEMS,
  ITEMS_LOADING,
  UPDATE_ITEM,
} from './types';

export const setItemsLoading = () => {
  return {
    type: ITEMS_LOADING,
  };
};

export const getItems = () => (dispatch: Function) => {
  dispatch(setItemsLoading());
  axios
    .get('/api/companies')
    .then((res) =>
      dispatch({
        type: GET_ITEMS,
        payload: res.data,
      })
    )
    .catch((err) =>
      dispatch(returnErrors(err.response.data, err.response.status))
    );
};

export const addItem = (item: IItem, handleSuccess: any) => (
  dispatch: Function,
  getState: Function
) => {
  dispatch({
    type: ADDING_ITEM,
  });
  axios
    .post('/api/companies', item, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: ADD_ITEM_SUCCESS,
        payload: res.data,
      });
      successNotifications(messages.USER_CREATE_SUCCESS);
      handleSuccess();
    })
    .catch((err) => {
      setTimeout(() => {
        dispatch({
          type: ADD_ITEM_FAILURE,
        });
      }, 1000);
      if (err.response.data.msg === 'Email already exists') {
        errorNotifications(messages.EMAIL_ALREADY);
      } else if (err.response.data.msg === 'Token is not valid') {
        errorNotifications(messages.INVALID_TOKEN);
        setTimeout(() => {
          dispatch({
            type: AUTH_ERROR,
          });
        }, 500);
      } else {
        if (err.response.data.code === 4021) {
          errorNotifications("会社のユーザーを作成できません。Zoomアカウントがホスト数に達しています。");
        } else {
          errorNotifications(messages.USER_CREATE_ERROR);
        }
      }
    });
};

export const updateItem = (id: string, item: IItem, handleSuccess: any) => (
  dispatch: Function,
  getState: Function
) => {
  axios
    .post(`/api/companies/${id}`, item, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: UPDATE_ITEM,
        payload: res.data,
      });
      successNotifications(messages.USER_UPDATE_SUCCESS);
      handleSuccess();
    })
    .catch((err) => {
      dispatch(returnErrors(err.response.data, err.response.status));
      if (err.response.data.msg === 'Email already exists') {
        errorNotifications(messages.EMAIL_ALREADY);
      } else if (err.response.data.msg === 'Token is not valid') {
        errorNotifications(messages.INVALID_TOKEN);
        setTimeout(() => {
          dispatch({
            type: AUTH_ERROR,
          });
        }, 500);
      } else {
        errorNotifications(messages.USER_CREATE_ERROR);
      }
    });
};

export const deleteItem = (id: string) => (
  dispatch: Function,
  getState: Function
) => {
  axios
    .delete(`/api/companies/${id}`, tokenConfig(getState))
    .then((res) => {
      setTimeout(() => {
        dispatch({
          type: DELETE_ITEM,
          payload: id,
        });
      }, 1000);
      successNotifications(messages.USER_DELETE_SUCCESS);
    })
    .catch((err) => {
      if (err.response.data.msg === 'Token is not valid') {
        errorNotifications(messages.INVALID_TOKEN);
        setTimeout(() => {
          dispatch({
            type: AUTH_ERROR,
          });
        }, 500);
      } else {
        errorNotifications(messages.USER_DELETE_ERROR);
      }
      setTimeout(() => {
        dispatch(returnErrors(err.response.data, err.response.status));
      }, 1000);
    });
};
