import React, { Fragment, useEffect } from 'react';
import { AiOutlineDelete } from 'react-icons/ai';
import { connect } from 'react-redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Alert, ListGroup, Table } from 'reactstrap';
import { deleteItem, getItems } from '../../flux/actions/itemActions';
import { IItemReduxProps, IShoppingList } from '../../types/interfaces';
import CompanyForm from './CompanyForm';
import ChangePassword from './ChangePassword';
import Tooltip from 'react-tooltip-lite';

const CompanyList = ({
  getItems,
  itemReducer,
  isAuthenticated,
  deleteItem,
  error,
  success,
}: IShoppingList) => {
  useEffect(() => {
    getItems();
  }, [getItems]);

  const handleDelete = (id: string) => {
    if (window.confirm('Do you really want to delete ?')) {
      deleteItem(id);
    }
  };

  const { items } = itemReducer;

  return (
    <Fragment>
      {success.message ? (
        <Alert color="success">{success.message}</Alert>
      ) : null}
      {/* {error.message ? <Alert color="danger">{error.message}</Alert> : null} */}

      <ListGroup>
        <TransitionGroup className="shopping-list">
          <Table bordered striped responsive>
            <thead>
              <tr>
                <th>#</th>
                <th>氏名</th>
                <th>会社名</th>
                <th>メールアドレス</th>
                <th>パスワード</th>
                <th>電話番号</th>
                <th>住所</th>
                <th>言語</th>
                {isAuthenticated ? <th>アクション</th> : null}
              </tr>
            </thead>
            <tbody>
              {items.map((company, index) => (
                <CSSTransition
                  key={company._id}
                  timeout={500}
                  classNames="fade"
                >
                  <tr>
                    <td>{index + 1}</td>
                    <td>{company.name}</td>
                    <td>{company.companyName}</td>
                    <td>{company.email}</td>
                    <td>{'*'.repeat(Math.min(company.password.length, 8))}</td>
                    <td>{company.phone}</td>
                    <td>{company.address}</td>
                    <td>{company.language}</td>
                    {isAuthenticated ? (
                      <td className="d-flex">
                        <CompanyForm dataForm={company} />
                        <ChangePassword company={company} />

                        <span
                          className="pointer mr-3"
                          onClick={() => handleDelete(company._id)}
                        >
                          <Tooltip content="ユーザー削除">
                            <AiOutlineDelete size={20} />
                          </Tooltip>
                        </span>
                      </td>
                    ) : null}
                  </tr>
                </CSSTransition>
              ))}
            </tbody>
          </Table>
        </TransitionGroup>
      </ListGroup>
    </Fragment>
  );
};

const mapStateToProps = (state: IItemReduxProps) => ({
  itemReducer: state.itemReducer,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
  success: state.success,
});

export default connect(mapStateToProps, { getItems, deleteItem })(CompanyList);
