import React, { Fragment } from 'react';
import { NavLink } from 'reactstrap';
import { connect } from 'react-redux';
import { logout } from '../../flux/actions/authActions';
import { ILogoutProps } from '../../types/interfaces';
import { FiLogOut } from 'react-icons/fi';

export const Logout = ({ logout }: ILogoutProps) => {
  return (
    <Fragment>
      <NavLink onClick={logout} href="#">
        ログアウト
        <FiLogOut className="ml-2" />
      </NavLink>
    </Fragment>
  );
};

export default connect(null, { logout })(Logout);
