import { store } from 'react-notifications-component';

export const successNotifications = (message: string) => {
  store.addNotification({
    title: '成功!',
    message: message,
    type: 'success',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      showIcon: true,
    },
  });
};

export const errorNotifications = (message: string) => {
  store.addNotification({
    title: 'エラー!',
    message: message,
    type: 'danger',
    insert: 'top',
    container: 'top-center',
    animationIn: ['animated', 'fadeIn'],
    animationOut: ['animated', 'fadeOut'],
    dismiss: {
      duration: 3000,
      showIcon: true,
    },
  });
};
