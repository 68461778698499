import React, { Fragment, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import { IoMdKey } from 'react-icons/io';
import { validations } from './validationsChangePassword';
import { validateForm } from '../../helper/validateForm';
import { updateItem } from '../../flux/actions/itemActions';
import { connect } from 'react-redux';
import Tooltip from 'react-tooltip-lite';

const initErrors = {
  password: null,
  passwordConfirm: null,
};

const ChangePassword = ({ company, updateItem }) => {
  const [modal, setModal] = useState(false);
  const [errors, setErrors] = useState(initErrors);
  const [dataForm, setDataForm] = useState({
    password: '',
    passwordConfirm: '',
  });

  const handleToggle = () => {
    setModal(!modal);
    if (!modal) {
      setErrors(initErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const data = {
      ...dataForm,
      [name]: value,
    };
    setDataForm(data);
  };

  const handleSuccess = () => {
    handleToggle();
    setDataForm({ password: '' });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const { errors, isFormValidate } = validateForm(validations, dataForm);
    setErrors(errors);

    if (isFormValidate) {
      updateItem(company._id, dataForm, handleSuccess);
    }
  };
  return (
    <Fragment>
      <span className="pointer mr-3" onClick={handleToggle}>
        <Tooltip content="パスワード変更">
          <IoMdKey size={22} />
        </Tooltip>
      </span>

      <Modal size="lg" isOpen={modal} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>パスワード変更</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleOnSubmit}>
            <FormGroup>
              <Label className="field-required">パスワード</Label>
              <Input
                type="password"
                name="password"
                value={dataForm.password}
                placeholder="パスワード"
                className={errors.password ? 'border-field-error' : ''}
                onChange={handleChange}
              />
              {errors.password ? (
                <div className="message-field-error mx-auto">
                  {`${errors.password}`}
                </div>
              ) : null}
            </FormGroup>

            {/* Password Confirm */}

            <FormGroup>
              <Label className="field-required">パスワード確認</Label>
              <Input
                type="password"
                name="passwordConfirm"
                value={dataForm.passwordConfirm}
                placeholder="パスワード確認"
                className={errors.passwordConfirm ? 'border-field-error' : ''}
                autoComplete="new-password"
                onChange={handleChange}
              />
              {errors.passwordConfirm ? (
                <div className="message-field-error mx-auto">
                  {`${errors.passwordConfirm}`}
                </div>
              ) : null}
            </FormGroup>

            <Button color="dark" style={{ marginTop: '2rem' }} block>
              <span>保存</span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  itemReducer: state.itemReducer,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { updateItem })(ChangePassword);
