export const messages = {
  FIELD_IS_REQUIRED: '必要項目ですので、ご入力してください。',

  INVALID_EMAIL: 'メール形式は正しくありません。再度ご入力してください。',

  EMAIL_ALREADY:
    '登録しているメールはも既存していますので、ほかのメールアドレスを登録してください。',

  INVALID_PASSWORD: 'パスワードは6~10文字列を設定してください。',

  INVALID_PASSWORD_CONFIRM: 'パスワード確認は6~10文字列を設定してください。',

  INVALID_PHONE: '電話番号の形式が正しくありません。数字でご入力してください。',

  EMAIL_SEND_SUCCESS: '送信しました。',

  EMAIL_SEND_ERROR: 'エラーが発生しました。再度お試しください。',

  USER_CREATE_SUCCESS: '登録が完了しました。',

  USER_CREATE_ERROR: 'エラーが発生しました。再度お試しください。',

  USER_UPDATE_SUCCESS: '情報更新が完了しました。',

  USER_UPDATE_ERROR: 'エラーが発生しました。再度お試しください。',

  USER_DELETE_SUCCESS: 'ユーザー削除が完了しました。',

  USER_DELETE_ERROR: 'エラーが発生しました。再度お試しください。',

  INVALID_NAME: '氏名は100文字以内にしてください。',

  INVALID_COMPANY_NAME: '会社名は100文字以内にしてください。',

  INVALID_PHONE_LENGTH: '電話番号は50文字以内にしてください。',

  INVALID_ADDRESS: '住所は100文字以内にしてください。',

  INVALID_EMAIL_LENGTH: 'メールアドレスは64文字以内にしてください。',

  INVALID_TOKEN: 'セクションが切れましたので、再度ログインしてください',

  PASSWORD_CONFIRM_NOT_MATCHED:
    '確認パスワードとパスワードが統一していないので、再度お試してください。',
};
