import {
  GET_ITEMS,
  ADDING_ITEM,
  ADD_ITEM_SUCCESS,
  ADD_ITEM_FAILURE,
  UPDATE_ITEM,
  DELETE_ITEM,
  ITEMS_LOADING,
  SENDING_MAIL,
  SEND_MAIL_SUCCESS,
  SEND_MAIL_FAILURE,
} from '../actions/types';
import { IAction, IItem } from '../../types/interfaces';

const initialState = {
  items: [],
  loading: false,
  sending: false,
  adding: false,
  error: {
    sendMail: null,
  },
};

interface IState {
  items: IItem[];
  loading: boolean;
  adding: boolean;
  sending: boolean;
  error: Object;
}

export default function (state: IState = initialState, action: IAction) {
  switch (action.type) {
    case ITEMS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_ITEMS:
      return {
        ...state,
        items: action.payload,
        loading: false,
      };
    case ADDING_ITEM:
      return {
        ...state,
        adding: true,
      };
    case ADD_ITEM_SUCCESS:
      return {
        ...state,
        items: [action.payload, ...state.items],
        adding: false,
      };
    case ADD_ITEM_FAILURE:
      return {
        ...state,
        adding: false,
      };
    case UPDATE_ITEM:
      const newItems = state.items.map((item) => {
        if (item._id === action.payload._id) {
          return action.payload;
        }
        return item;
      });
      return {
        ...state,
        items: newItems,
      };
    case DELETE_ITEM:
      return {
        ...state,
        items: state.items.filter((item) => item._id !== action.payload),
      };

    case SENDING_MAIL:
      return {
        ...state,
        sending: true,
      };
    case SEND_MAIL_SUCCESS:
      return {
        ...state,
        sending: false,
        error: {
          sendMail: null,
        },
      };
    case SEND_MAIL_FAILURE:
      return {
        ...state,
        sending: false,
        error: {
          sendMail: action.payload,
        },
      };
    default:
      return state;
  }
}
