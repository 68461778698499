import React, { useState, Fragment, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
} from 'reactstrap';
import { connect } from 'react-redux';
import { addItem, updateItem } from '../../flux/actions/itemActions';
import { IItemReduxProps, IItemModal, ITarget } from '../../types/interfaces';
import { validationsFormCreate } from './validationsCreate';
import { validationsFormUpdate } from './validationsUpdate';
import { validateForm } from '../../helper/validateForm';
import { AiOutlineUserAdd, AiOutlineLoading } from 'react-icons/ai';
import { initialValues } from './initialValues';
import { FiEdit } from 'react-icons/fi';
import Tooltip from 'react-tooltip-lite';

const initErrors = {
  name: null,
  companyName: null,
  email: null,
  password: null,
  passwordConfirm: null,
  phone: null,
  address: null,
};

const CompanyForm = ({
  dataForm,
  addItem,
  updateItem,
  itemReducer,
}: IItemModal) => {
  const [modal, setModal] = useState(false);

  const [dataCompany, setDataCompany] = useState(
    dataForm ? dataForm : initialValues
  );

  const [errors, setErrors] = useState(initErrors);

  useEffect(() => {
    if (dataForm) setDataCompany(dataForm);
  }, [modal]);

  const handleToggle = () => {
    setModal(!modal);
    if (!modal) {
      setErrors(initErrors);
    }
  };

  const handleChange = (e: ITarget) => {
    const { name, value } = e.target;
    const dataForm = {
      ...dataCompany,
      [name]: value,
    };
    setDataCompany(dataForm);
  };

  const handleSuccess = () => {
    handleToggle();
    setDataCompany(initialValues);
  };

  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    const validations = dataForm
      ? validationsFormUpdate
      : validationsFormCreate;
    const { errors, isFormValidate } = validateForm(validations, dataCompany);
    setErrors(errors);
    if (isFormValidate) {
      if (dataForm) {
        const { password, ...dataUpdate } = dataCompany;
        updateItem(dataForm._id, dataUpdate, handleSuccess);
      } else {
        addItem(dataCompany, handleSuccess);
      }
    }
  };

  return (
    <Fragment>
      {dataForm ? (
        <span className="pointer mr-3" onClick={handleToggle}>
          <Tooltip content="ユーザー変更">
            <FiEdit />
          </Tooltip>
        </span>
      ) : (
        <div className="add-company-box">
          <Button
            color="dark"
            style={{ marginBottom: '2rem' }}
            onClick={handleToggle}
          >
            <AiOutlineUserAdd size={20} />
            新規作成
          </Button>
        </div>
      )}
      

      <Modal size="lg" isOpen={modal} toggle={handleToggle}>
        <ModalHeader toggle={handleToggle}>
          {dataForm ? 'アカウント情報更新' : '新規作成'}
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleOnSubmit}>
            {/* Name */}
            <FormGroup>
              <Label className="field-required">氏名</Label>
              <Input
                type="text"
                name="name"
                value={dataCompany.name}
                placeholder="氏名"
                className={errors.name ? 'border-field-error' : ''}
                onChange={handleChange}
              />
              {errors.name ? (
                <div className="message-field-error mx-auto">{`${errors.name}`}</div>
              ) : null}
            </FormGroup>
            {/* Company Name */}
            <FormGroup>
              <Label className="field-required">会社名</Label>
              <Input
                type="text"
                name="companyName"
                value={dataCompany.companyName}
                placeholder="会社名"
                className={errors.companyName ? 'border-field-error' : ''}
                onChange={handleChange}
              />
              {errors.companyName ? (
                <div className="message-field-error mx-auto">
                  {`${errors.companyName}`}
                </div>
              ) : null}
            </FormGroup>

            {/* Email */}
            <FormGroup>
              <Label className="field-required">メールアドレス</Label>
              <Input
                type="text"
                name="email"
                placeholder="メールアドレス"
                value={dataCompany.email}
                className={errors.email ? 'border-field-error' : ''}
                onChange={handleChange}
                autoComplete="new-password"
              />
              {errors.email ? (
                <div className="message-field-error mx-auto">
                  {`${errors.email}`}
                </div>
              ) : null}
            </FormGroup>

            {/* Password */}
            {dataForm ? null : (
              <FormGroup>
                <Label className="field-required">パスワード</Label>
                <Input
                  type="password"
                  name="password"
                  value={dataCompany.password}
                  placeholder="パスワード"
                  className={errors.password ? 'border-field-error' : ''}
                  autoComplete="new-password"
                  onChange={handleChange}
                />
                {errors.password ? (
                  <div className="message-field-error mx-auto">
                    {`${errors.password}`}
                  </div>
                ) : null}
              </FormGroup>
            )}

            {/* Password Confirm */}
            {dataForm ? null : (
              <FormGroup>
                <Label className="field-required">パスワード確認</Label>
                <Input
                  type="password"
                  name="passwordConfirm"
                  value={dataCompany.passwordConfirm}
                  placeholder="パスワード確認"
                  className={errors.passwordConfirm ? 'border-field-error' : ''}
                  autoComplete="new-password"
                  onChange={handleChange}
                />
                {errors.passwordConfirm ? (
                  <div className="message-field-error mx-auto">
                    {`${errors.passwordConfirm}`}
                  </div>
                ) : null}
              </FormGroup>
            )}

            {/* Phone */}
            <FormGroup>
              <Label>電話番号</Label>
              <Input
                type="text"
                name="phone"
                value={dataCompany.phone}
                placeholder="電話番号"
                onChange={handleChange}
              />
              {errors.phone ? (
                <div className="message-field-error mx-auto">{`${errors.phone}`}</div>
              ) : null}
            </FormGroup>
            {/* Address */}
            <FormGroup>
              <Label>住所</Label>
              <Input
                type="text"
                name="address"
                value={dataCompany.address}
                placeholder="住所"
                onChange={handleChange}
              />
              {errors.address ? (
                <div className="message-field-error mx-auto">{`${errors.address}`}</div>
              ) : null}
            </FormGroup>
            {/* Language */}
            <FormGroup>
              <Label>言語</Label>
              <Input
                type="select"
                name="language"
                value={dataCompany.language}
                defaultValue="japanese"
                onChange={handleChange}
              >
                <option value="japanese">日本語</option>
                <option value="english">英語</option>
              </Input>
            </FormGroup>
            <Button
              color="dark"
              style={{ marginTop: '2rem' }}
              block
              disabled={itemReducer.adding}
            >
              {itemReducer.adding ? (
                <AiOutlineLoading size={20} className="loading mr-3" />
              ) : (
                ''
              )}
              <span>保存</span>
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

const mapStateToProps = (state: IItemReduxProps) => ({
  itemReducer: state.itemReducer,
  isAuthenticated: state.auth.isAuthenticated,
  error: state.error,
});

export default connect(mapStateToProps, { addItem, updateItem })(CompanyForm);
