import { combineReducers } from 'redux';
import itemReducer from './itemReducer';
import successReducer from './successReducer';
import errorReducer from './errorReducer';
import authReducer from './authReducer';

export default combineReducers({
  itemReducer,
  success: successReducer,
  error: errorReducer,
  auth: authReducer,
});
